import React from "react";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import Blurb from "../components/Blurb";
import Title from "../components/Title";
import Section from "../components/Section";
import styles from "./about.module.css";
import breakpoints from "../style/Breakpoints";

class About extends React.Component {
  render() {
    const { shareInfo, blurb, staffList } = this.props.data.page;
    const { splash } = this.props.data.index;
    splash.image.childImageSharp.fluid.sizes = "98vw";
    return (
      <>
        <Helmet>
          <title>About Us</title>
          <meta property="og:title" content="Lunsford Ridge Farm | About Us" />
          <meta
            property="og:url"
            content="https://www.lunsfordridgefarm.com/about"
          />
        </Helmet>
        {shareInfo && shareInfo.image && shareInfo.description && (
          <Helmet>
            <meta name="description" content={shareInfo.description} />
            <meta property="og:description" content={shareInfo.description} />
            <meta
              property="og:image"
              content={`https://www.lunsfordridgefarm.com${shareInfo.image.childImageSharp.fixed.src}`}
            />
          </Helmet>
        )}
        <Section>
          <Title>History</Title>
          <Blurb blurb={blurb} />
        </Section>
        <Section>
          <Title>Meet the Staff</Title>
          <ul className={styles.staffwrapper}>
            {staffList.map((staff) => {
              const media = `(min-width: ${breakpoints.small}) 35vw, 25vw`;
              staff.photo.childImageSharp.fluid.sizes = media;
              return (
                <li className={styles.staff} key={staff.name}>
                  <Img
                    className={styles.staff__image}
                    fluid={staff.photo.childImageSharp.fluid}
                    fadeIn={false}
                    alt={`Staff: ${staff.name}`}
                  />
                  <div className={styles.staff__text}>
                    <p>{staff.name}</p>
                    <p>{staff.role}</p>
                  </div>
                </li>
              );
            })}
          </ul>
        </Section>
        <Section>
          <Title>Photo Gallery</Title>
          <Link to="/gallery" className={styles.gallery}>
            <Img
              className={styles.gallery__image}
              fluid={splash.image.childImageSharp.fluid}
              fadeIn={false}
              alt="Photo Gallery Hero"
            />
            <div className={styles.gallery__text}>CLICK TO VIEW MORE</div>
          </Link>
        </Section>
      </>
    );
  }
}

export default About;

export const pageQuery = graphql`
  query {
    index: pagesJson(pageName: { eq: "index" }) {
      splash {
        header
        subheader
        image {
          childImageSharp {
            fluid(quality: 100, cropFocus: ATTENTION, maxWidth: 1250) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    page: pagesJson(pageName: { eq: "about" }) {
      ...OpenGraphFragment
      ...BlurbFragment
      staffList {
        name
        role
        photo {
          childImageSharp {
            fluid(quality: 100, cropFocus: ATTENTION, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
